import React, {useEffect} from 'react';
import {useControllerProps} from '../../ControllerContext';
import {SectionNotification} from 'wix-ui-tpa/SectionNotification';
import {useTranslation} from 'yoshi-flow-editor-runtime';
import Alert from 'wix-ui-icons-common/on-stage/Alert';
import s from './MinimumOrderAmount.scss';
import {ContinueShoppingBiOrigin} from '../../../constants';

export enum MinimumOrderAmountDataHook {
  Notification = 'MinimumOrderAmountDataHook.Notification',
  ContinueShopping = 'MinimumOrderAmountDataHook.ContinueShopping',
  NotificationText = 'MinimumOrderAmountDataHook.NotificationText',
}

export const MinimumOrderAmount = () => {
  const {continueShopping} = useControllerProps().navigationStore;
  const {minimumOrderAmount} = useControllerProps().cartStore.cart;
  const {sendMinimumOrderMessageShownBi} = useControllerProps().cartStore;
  const {isCheckoutButtonDisabled} = useControllerProps().navigationStore;
  const {t} = useTranslation();

  useEffect(() => {
    sendMinimumOrderMessageShownBi(isCheckoutButtonDisabled);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={s.notification}>
      <SectionNotification type="alert" data-hook={MinimumOrderAmountDataHook.Notification}>
        <SectionNotification.Icon icon={<Alert className={s.icon} />} />
        <SectionNotification.Text>
          <span data-hook={MinimumOrderAmountDataHook.NotificationText}>
            {t('cart.note.minimumOrder.text', {
              minAmount: minimumOrderAmount.formattedConvertedValue,
              additionalAmount: minimumOrderAmount.formattedConvertedDiff,
            })}
          </span>
          <button
            data-hook={MinimumOrderAmountDataHook.ContinueShopping}
            className={s.link}
            type="button"
            onClick={() => continueShopping(ContinueShoppingBiOrigin.minimumOrder)}>
            {t('cart.note.minimumOrder.link')}
          </button>
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  );
};
